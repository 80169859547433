import React from "react";
import "./style.scss";
import "../../assets/fonts/fontello-771c82e0/css/fontello.css";
import { trackEvent } from "../../util/google-analytics";

class Links extends React.Component {
  render() {
    const author = this.props.data;
    const links = {
      telegram: author.telegram,
      twitter: author.twitter,
      github: author.github,
      vk: author.vk,
      rss: author.rss,
      email: author.email,
    };

    return (
      <div className="links">
        <ul className="links__list">
          <li className="links__list-item">
            <a
              href={`https://www.twitter.com/${links.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => trackEvent(e, "Links", "Click", "Twitter")}
            >
              <i className="icon-twitter" />
            </a>
          </li>
          <li className="links__list-item">
            <a
              href="/rss.xml"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => trackEvent(e, "Links", "Click", "RSS")}
            >
              <i className="icon-rss" />
            </a>
          </li>
          <li className="links__list-item">
            <a
              href={`mailto:${links.email}`}
              onClick={(e) => trackEvent(e, "Links", "Click", "Email")}
            >
              <i className="icon-mail" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Links;
