import React from "react";
import { Link } from "gatsby";
import "./style.scss";
import { trackEvent } from "../../util/google-analytics";
import { List } from "../Sound";
import { Sparkles } from "../Sparkle";
class Feature extends React.Component {
  render() {
    const feature = this.props.data;
    const menuBlock = (
      <div>
        <Link className="post__readmore" to="#community">
          <Sparkles id="community">Community Building 🙌</Sparkles>
        </Link>
        <ul className="menu__list">
          {feature.map((item) => (
            <li className="menu__list-item" key={item.path}>
              <Link
                to={item.path}
                className="menu__list-item-link"
                activeClassName="menu__list-item-link menu__list-item-link--active"
                onClick={(e) =>
                  trackEvent(e, "Feature", "Click", `${item.label}`)
                }
                target="_blank"
              >
                <u> {item.label}</u>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );

    return <nav className="menu">{menuBlock}</nav>;
  }
}

export default Feature;
