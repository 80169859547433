import React from "react";
import { Link } from "gatsby";
import "./style.scss";
import { trackEvent } from "../../util/google-analytics";
import { List } from "../Sound";
import { Sparkles } from "../Sparkle";
class Deployed extends React.Component {
  render() {
    const deployed = this.props.data;
    const menuBlock = (
      <div>
        <Link className="post__readmore" to="#apps">
          <Sparkles id="apps">Apps 🧰</Sparkles>
        </Link>
        <ul className="menu__list">
          {deployed.map((item) => (
            <li className="menu__list-item" key={item.path}>
              <Link
                to={item.path}
                className="menu__list-item-link"
                activeClassName="menu__list-item-link menu__list-item-link--active"
                onClick={(e) =>
                  trackEvent(
                    e,
                    "Deployed",
                    "Click",
                    `${window.location.href == item.label
                      ? "#"
                      : window.location.href
                    }`
                  )
                }
                target="_blank"
              >
                <u>{item.label}</u>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );

    return <nav className="menu">{menuBlock}</nav>;
  }
}

export default Deployed;
