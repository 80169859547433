import React from "react";
import useSound from "use-sound";
import { Link } from "gatsby";
import { trackEvent } from "../../util/google-analytics";

export function List(item) {
  return (
    <Link
      to={item.path}
      className="menu__list-item-link"
      activeClassName="menu__list-item-link menu__list-item-link--active"
      onClick={(e) => trackEvent(e, "Menu", "Click", `${item.label}`)}
    >
      {item.label}
    </Link>
  );
}
