import { Link } from "gatsby";
import get from "lodash/get";
import React from "react";
import profilePic from "../../pages/logo-main.png";
import { trackEvent } from "../../util/google-analytics";
import CarbonAds from "../CarbonAds/carbonads";
import Deployed from "../Deployed";
import EmailList from "../EmailList";
import Feature from "../Feature";
import Links from "../Links";
import Menu from "../Menu";
import "./style.scss";
class Sidebar extends React.Component {
  render() {
    const { location } = this.props;
    const {
      author,
      subtitle,
      copyright,
      menu,
      feature,
      deployed,
    } = this.props.data.site.siteMetadata;
    const isHomePage = get(location, "pathname", "/") === "/";
    const kofiStyle = {
      height: "50px",
      border: "0",
      alt: "Buy Me a Coffee at ko-fi.com",
      marginTop: "10px",
    };
    /* eslint-disable jsx-a11y/img-redundant-alt */
    const authorBlock = (
      <div>
        <Link to="/">
          <img
            src={profilePic}
            className="sidebar__author-photo"
            width="100%"
            height="100%"
            alt={author.name}
            onClick={(e) => trackEvent(e, "Sidebar", "Click", "Profile Pic")}
          />
        </Link>
        {isHomePage ? (
          <h1 className="sidebar__author-title">
            <Link
              className="sidebar__author-title-link"
              to="/"
              onClick={(e) =>
                trackEvent(e, "Home Page Sidebar", "Click", "Author Title")
              }
            >
              {author.name}
            </Link>
          </h1>
        ) : (
          <h2 className="sidebar__author-title">
            <Link
              className="sidebar__author-title-link"
              to="/"
              onClick={(e) =>
                trackEvent(e, "Non Home Page Sidebar", "Click", "Author Title")
              }
            >
              {author.name}
            </Link>
          </h2>
        )}
        <p className="sidebar__author-subtitle">{subtitle}</p>
      </div>
    );
    /* eslint-enable jsx-a11y/img-redundant-alt */

    return (
      <div className="sidebar">
        <div className="sidebar__inner">
          <div className="sidebar__author">{authorBlock}</div>
          <div>
            <CarbonAds></CarbonAds>
            <Menu data={menu} />
            <Deployed data={deployed} />
            <Links data={author} />
            <Feature data={feature} />
            <EmailList {...this.props} />
            <div className="sidebar__copyright">
              <p>{copyright}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
