import React from "react";
import { Link } from "gatsby";
import "./style.scss";
import { trackEvent } from "../../util/google-analytics";
import { List } from "../Sound";
class Menu extends React.Component {
  render() {
    const menu = this.props.data;
    const menuBlock = (
      <ul className="menu__list">
        {menu.map((item) => (
          <li className="menu__list-item" key={item.path}>
            {List(item)}
          </li>
        ))}
      </ul>
    );

    return <nav className="menu">{menuBlock}</nav>;
  }
}

export default Menu;
